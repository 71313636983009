import {
	AfterViewInit,
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component, effect,
    ElementRef,
    EventEmitter, inject, input,
    Input, InputSignal,
    OnChanges,
    OnInit,
    Output, SimpleChanges,
    ViewChild,
} from '@angular/core';
import {MatIcon} from "@angular/material/icon";
import {MatIconButton} from "@angular/material/button";
import {NgIf} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatFormField, MatPrefix, MatSuffix} from "@angular/material/form-field";
import {VoiceRecognitionService} from "@flow/services/voice-recongnition/voicerecognition.service";
import {MatInput} from "@angular/material/input";

@Component({
  selector: 'flowbot-input',
  standalone: true,
	imports: [
		MatIcon,
		MatIconButton,
		NgIf,
		ReactiveFormsModule,
		FormsModule,
		MatFormField,
		MatSuffix,
		MatPrefix,
		MatInput,
	],
  templateUrl: './flowbot-input.component.html',
  styles: [
	  `
		  :host ::ng-deep .mat-mdc-text-field-wrapper{
			  border-radius: 32px !important;
		  }


	  `
  ],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowbotInputComponent implements OnInit, OnChanges, AfterViewInit {
	@ViewChild('inputField') inputField: ElementRef;
	@Output() newMessage: EventEmitter<any> = new EventEmitter();
	@Input() placeHolderText: string = 'Type your message... Or use the mic button...';
	searchInput = input<string>('');
	isUserSpeaking: boolean = false;
	finalTranscript: string = '';
	interimTranscript: string = '';
	protected hasTyped: boolean;
	private userInput: string;
    disabled: InputSignal<boolean> = input.required<boolean>();
	constructor(private voiceRecognition: VoiceRecognitionService, private cdr: ChangeDetectorRef) {
		this.voiceRecognition.initialize((transcript: string, isFinal: boolean) => {
			this.updateUserInput(transcript, isFinal);
		});
        effect(() => {
            this.cdr.detectChanges();
        });
	}

	ngOnInit(): void {
	}

	ngAfterViewInit(): void {
		if (this.inputField) {
			this.inputField.nativeElement.focus();
			const input = this.searchInput();
			this.inputField.nativeElement.value = input;
			this.hasTyped = input !== '';
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['placeHolderText'] && changes['placeHolderText'].currentValue !== 'Type your message... Or use the mic button...') {
			this.placeHolderText = changes['placeHolderText'].currentValue;
		}
	}

	startRecording() {
		this.voiceRecognition.speak('');
		this.isUserSpeaking = true;
		this.voiceRecognition.start();
		this.finalTranscript = '';  // Clear previous text
		this.interimTranscript = '';

	}

	stopRecording() {
		this.isUserSpeaking = false;
		this.voiceRecognition.stop();
		this.sendMessage();

	}

	sendMessage(): void {

		this.newMessage.emit(this.inputField.nativeElement.value);
		this.inputField.nativeElement.value = '';

	}






	adjustHeight(event: Event): void {
		const textarea = event.target as HTMLTextAreaElement;
		textarea.style.height = 'auto';  // Reset height
		textarea.style.height = textarea.scrollHeight + 'px';

	}



	private updateUserInput(transcript: string, isFinal: boolean) {
		if (isFinal) {
			// Append final results to the final transcript and clear interim results
			this.finalTranscript += transcript + ' ';
			this.interimTranscript = '';
			this.userInput = this.finalTranscript;
		} else {
			// Update interim results for real-time feedback
			this.interimTranscript = transcript;
			this.userInput = this.interimTranscript;
		}
	}

	onInput(): void {
		this.hasTyped = true; // Set typing flag when user types
	}
}
